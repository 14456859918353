import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { getServiceOptions } from "Components/CompanySettings/DeliverySettings/functions";
import { useState, useEffect, useContext } from "react";
import {
  addPricingList,
  getPricingLists,
  getZoneSets,
  removePricingList,
  updatePricingList,
} from "../functions";
import AddPriceList from "./AddPriceList";
import { VishcorpPricingContext } from "./context";
import DistanceBased from "./DistanceBased";
import FlatRate from "./FlatRate";
import ZoneBased from "./ZoneBased";
import { VishcorpContext } from "Context";
import UploadPricingList from "./UploadPricingList";
import { AttachFile, FileUpload } from "@mui/icons-material";
import papaparse from "papaparse";
import ZoneSetDetails from "../ZoneSetDetails";

export default function PriceLists(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedZoneSet, setSelectedZoneSet] = useState("default");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [openAddPricingList, setOpenAddPricingList] = useState(false);
  const [openRemovePricingList, setOpenRemovePricingList] = useState(false);
  const [zoneSetList, setZoneSetList] = useState(undefined);
  const [pricingLists, setPricingLists] = useState(undefined);
  const [selectedPricingList, setSelectedPricingList] = useState(undefined);
  const [uploadPricingList, setUploadPricingList] = useState(false);
  const [serviceOptions, setServiceOptions] = useState(undefined);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [zonePricingListTemplate, setZonePricingListTemplate] =
    useState(undefined);

  const convertToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  function jsonToCsv(items) {
    const header = Object.keys(items[0]);
    const headerString = header.join(",");
    // handle null or undefined values here
    const replacer = (key, value) => value ?? "";
    const rowItems = items.map((row) => {
      return header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",");
    });
    // join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join("\r\n");
    return csv;
  }

  const downloadCSV = (data) => {
    if (!zonePricingListTemplate) return;
    const csvData = new Blob([jsonToCsv(data)], {
      type: "text/csv",
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `template.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const refreshPricingLists = async () => {
    await getPricingLists(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        if (selectedPricingList !== undefined) {
          setSelectedPricingList(
            res.find(
              (e) => e?.pricingListName === selectedPricingList?.pricingListName
            )
          );
        } else {
          setSelectedPricingList(undefined);
        }
        setPricingLists(res);
      })
      .catch(console.error);
  };

  useEffect(() => {
    (async () => {
      await getPricingLists(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setPricingLists(res);
        })
        .catch(console.error);
    })();
    getServiceOptions(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => setServiceOptions(res))
      .catch(console.error);
  }, [orgSettings]);

  useEffect(() => {
    (async () => {
      await getZoneSets(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setZoneSetList(res);
        })
        .catch(console.error);
    })();
  }, [orgSettings]);

  return (
    <VishcorpPricingContext.Provider value={{ pricingLists }}>
      <Grid container>
        <Grid item xs={4}>
          <TableContainer component={Paper} sx={{ height: "70vh" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pricelist</TableCell>
                  <TableCell>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b>Type</b>
                      <Tooltip title="Upload Zone Set CSV">
                        <IconButton color="primary" onClick={handleClickOpen}>
                          <FileUpload />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingLists?.length > 0 &&
                  pricingLists?.map((e, i) => (
                    <TableRow
                      hover
                      selected={
                        selectedPricingList?.pricingListName ===
                        e?.pricingListName
                      }
                      onClick={() => setSelectedPricingList(e)}
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{e?.pricingListName}</TableCell>
                      <TableCell>
                        {e?.pricingListType === "zoneBased" && "Zone Based"}
                        {e?.pricingListType === "flatBased" && "Flat Based"}
                        {e?.pricingListType === "distanceBased" &&
                          "Distance Based"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => setOpenAddPricingList(true)}
                style={{ height: "100%" }}
              >
                Add Pricing List
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => {
                  if (!selectedPricingList) {
                    return;
                  }
                  setOpenRemovePricingList(true);
                }}
                style={{ height: "100%" }}
              >
                Remove
              </Button>
            </Grid>
            <br />

            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => {
                  setUploadPricingList(true);
                }}
                style={{ height: "100%" }}
              >
                Upload Pricing List
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginLeft: 2, height: "100vh" }}
        />
        <Grid item xs={7.5}>
          {selectedPricingList?.pricingListType === "zoneBased" && (
            <ZoneBased
              onSavePriceList={async () => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  selectedPricingList?.pricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              pricingList={selectedPricingList}
              setSelectedPricingList={setSelectedPricingList}
              serviceOptions={serviceOptions}
            />
          )}
          {selectedPricingList?.pricingListType === "flatBased" && (
            <FlatRate
              onSavePriceList={async () => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  selectedPricingList?.pricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              pricingList={selectedPricingList}
              setSelectedPricingList={setSelectedPricingList}
              serviceOptions={serviceOptions}
            />
          )}
          {selectedPricingList?.pricingListType === "distanceBased" && (
            <DistanceBased
              onSavePriceList={async (newPricingListDetails) => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  newPricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              onRemoveDistanceRange={async (newPricingListDetails) => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  newPricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              pricingList={selectedPricingList}
              setSelectedPricingList={setSelectedPricingList}
              serviceOptions={serviceOptions}
            />
          )}
        </Grid>
        <AlertDialog
          customControls={false}
          open={uploadPricingList}
          handleClose={() => setUploadPricingList(false)}
          // dialogTitle={`New ${label} Attribute`}
          dialogContent={
            <UploadPricingList
              zoneSetList={zoneSetList}
              onCancel={() => setUploadPricingList(false)}
              organizationID={orgSettings?.organizationID}
              organizationTablePrefix={orgSettings?.organizationTablePrefix}
            />
          }
        />
        <AlertDialog
          customControls={false}
          open={openAddPricingList}
          handleClose={() => setOpenAddPricingList(false)}
          // dialogTitle={`New ${label} Attribute`}
          dialogContent={
            <AddPriceList
              zoneSetList={zoneSetList}
              onCancel={() => setOpenAddPricingList(false)}
              onAddPriceList={async (pricingListDetails) => {
                try {
                  if (
                    pricingLists?.findIndex(
                      (e) =>
                        String(e?.pricingListName) ===
                        String(pricingListDetails?.pricingListName)
                    ) === -1 ||
                    pricingLists?.findIndex(
                      (e) =>
                        String(e?.pricingListName) ===
                        String(pricingListDetails?.pricingListName)
                    ) === undefined
                  ) {
                    await addPricingList(
                      pricingListDetails,
                      orgSettings?.organizationID,
                      orgSettings?.organizationTablePrefix
                    );
                    await refreshPricingLists();
                    setOpenAlertMessage(true);
                    setOpenAddPricingList(false);
                  } else {
                    setOpenErrorMessage(true);
                  }
                } catch (error) {
                  console.error(error);
                }
              }}
            />
          }
        />
        <AlertDialog
          open={openRemovePricingList}
          handleClose={() => setOpenRemovePricingList(false)}
          dialogTitle={`Confirm Delete Pricing List: ${selectedPricingList?.pricingListName}?`}
          handleConfirm={async () => {
            try {
              await removePricingList(
                selectedPricingList?.pricingListName,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              await refreshPricingLists();
              setOpenRemovePricingList(false);
              setOpenAlertMessage(true);
              setSelectedPricingList(undefined);
            } catch (error) {
              console.error(error);
            }
          }}
        />
        <Snackbar
          open={openAlertMessage}
          autoHideDuration={6000}
          onClose={() => {
            setOpenAlertMessage(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpenAlertMessage(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Settings saved.
          </Alert>
        </Snackbar>

        <Snackbar
          open={openErrorMessage}
          autoHideDuration={6000}
          onClose={() => {
            setOpenErrorMessage(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpenErrorMessage(false);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            Pricing List already exists.
          </Alert>
        </Snackbar>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Upload File</DialogTitle>
          <DialogContent>
            <Select
              fullWidth
              size="small"
              value={selectedZoneSet}
              onChange={(e) => {
                setSelectedZoneSet(e.target.value);
              }}
            >
              <MenuItem disabled value="default">
                Select a Zone Set
              </MenuItem>
              {zoneSetList
                ? zoneSetList.map((e) => (
                    <MenuItem
                      value={e.zoneSetName}
                      key={`option-${e.zoneSetName}`}
                    >
                      {e.zoneSetName}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <Typography variant="body2" color="error" gutterBottom>
              Note: It is very important that the file name matches the price
              list name you want to upload.
            </Typography>
            {/* Download Template Button */}
            <Button
              variant="outlined"
              size="small"
              endIcon={<AttachFile />}
              onClick={() => {
                if (selectedZoneSet === "default") return;
                var selctedZoneSetDetails = zoneSetList?.find(
                  (e) => e?.zoneSetName === selectedZoneSet
                );
                var obj = selctedZoneSetDetails?.zones;
                var tempZonePricingListTemplate = obj
                  ?.map((e) => e?.zoneName)
                  ?.reduce((acc, i) => {
                    acc.push({
                      zoneName: i,
                      ...(serviceOptions?.reduce((acc2, j) => {
                        acc2[j?.name] = 0;
                        return acc2;
                      }, {}) ?? {}),
                    });
                    return acc;
                  }, []);
                downloadCSV(tempZonePricingListTemplate);
                setZonePricingListTemplate(tempZonePricingListTemplate);
                console.log({
                  organizationID: orgSettings?.organizationID,
                  pricingListDetails: {
                    backUpPricingList: "",
                    zoneSetName: selctedZoneSetDetails?.zoneSetName,
                    defaultZone: obj?.[0]?.zoneName,
                    zones: obj,
                    zonePricingList: tempZonePricingListTemplate,
                  },
                  pricingListName: "",
                  pricingListType: "zoneBased",
                });
              }}
            >
              Download Template File
            </Button>
            <br />
            {/* File Upload Input */}
            <TextField
              margin="dense"
              id="file"
              label="Upload File"
              type="file"
              fullWidth
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: ".csv", // Restrict file types if needed
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (selectedFile) {
                  papaparse.parse(selectedFile, {
                    complete: (result) => {
                      console.log(result);
                    },
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                  });
                }
              }}
              color="primary"
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </VishcorpPricingContext.Provider>
  );
}
