import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import AddCostCenter from "./AddCostCenter";
import { addCostCenter, deleteCostCenter, getCustomer } from "./functions";
import { VishcorpContext } from "Context";
import { useForm } from "react-hook-form";
import { GAutoComplete, addressSearch } from "Components/GAutoComplete";
import { getCostCenterCustomer, updateCustomerAPI } from "API/Customers";
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { VishcorpContext } from "Context";

export default function EditCustomer(props) {
  const {
    register,
    // handleSubmit,
    formState: { errors },
    // control,
  } = useForm();

  const [pickupOptions, setPickupOptions] = useState([]);
  const [customerAddressOptions, setCustomerAddressOptions] = useState([]);
  const { orgSettings } = useContext(VishcorpContext);
  const { selectedCustomer, setSelectedCustomer, handleClose, handleConfirm } =
    props;
  const [costCenters, setCostCenters] = useState(selectedCustomer?.costCenters);
  const [selectedCostCenter, setSelectedCostCenter] = useState(undefined);
  const [openAddCostCenter, setOpenAddCostCenter] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [openDeleteCostCenter, setOpenDeleteCostCenter] = useState(false);
  const [selectedCostCenters, setSelectedCostCenters] = useState([]);

  const [confirmUpdateCustomer, setConfirmUpdateCustomer] = useState(false);

  const [customerDetails, setCustomerDetails] = useState({});

  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }
    setCustomerDetails(selectedCustomer);
    setSelectedCostCenters(selectedCustomer?.selectedCostCenters);
  }, [selectedCustomer]);

  const updateCustomerDetails = (property, value) => {
    setCustomerDetails((preVal) => ({
      ...preVal,
      [property]: value,
    }));
  };

  const refreshCustomer = async () => {
    if (!selectedCustomer?.customerID) {
      return;
    }
    await getCustomer(
      selectedCustomer?.customerID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setSelectedCustomer(res);
        // setCostCenters(res?.costCenters ?? []);
      })
      .catch(console.error);

    await getCostCenterCustomer(orgSettings.organizationTablePrefix)
      .then((res) => setCostCenters(res?.Items))
      .catch(console.error);
  };

  useEffect(() => {
    if (!orgSettings) return;
    getCostCenterCustomer(orgSettings.organizationTablePrefix)
      .then((res) => setCostCenters(res?.Items))
      .catch(console.error);
  }, [orgSettings]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Property</b>
              </TableCell>
              <TableCell>
                <b>Value</b>
              </TableCell>
            </TableRow>
          </TableHead>
          {selectedCustomer && (
            <TableBody>
              {/* <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Customer Number</b>
                </TableCell>
                <TableCell>{selectedCustomer?.customerNumber}</TableCell>
              </TableRow> */}
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Customer Number</b>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    error={errors?.customerNumber && true}
                    helperText={errors?.customerNumber?.message}
                    {...register("customerNumber", {
                      required: "Required*",
                    })}
                    label="Customer Number"
                    variant="outlined"
                    value={customerDetails?.customerNumber ?? ""}
                    onChange={(e) => {
                      updateCustomerDetails("customerNumber", e?.target?.value);
                    }}
                    style={{ width: "100%", marginBottom: 15 }}
                  />
                  {/* {selectedCustomer?.customerNumber} */}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Customer Name</b>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    label="Name"
                    error={errors?.customerName && true}
                    helperText={errors?.customerName?.message}
                    {...register("customerName", {
                      required: "Required*",
                    })}
                    variant="outlined"
                    value={customerDetails?.customerName ?? ""}
                    onChange={(e) =>
                      updateCustomerDetails("customerName", e?.target?.value)
                    }
                    style={{ width: "100%", marginBottom: 15 }}
                  />
                  {/* {selectedCustomer?.customerName} */}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Contact Phone</b>
                </TableCell>
                <TableCell>
                  <TextField
                    {...register("phone", {
                      required: "Required*",
                    })}
                    error={errors?.phone && true}
                    helperText={errors?.phone?.message}
                    size="small"
                    label="Phone"
                    variant="outlined"
                    // value={customerDetails?.phone ?? ""}
                    value={
                      String(customerDetails?.phone)?.includes("+")
                        ? String(customerDetails?.phone).slice(3)
                        : String(customerDetails?.phone)
                    }
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                      if (onlyNums.length < 10) {
                        updateCustomerDetails("phone", onlyNums);
                      } else if (onlyNums.length === 10) {
                        const number = onlyNums.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        );
                        updateCustomerDetails("phone", number);
                      }
                    }}
                    style={{ width: "100%", marginBottom: 15 }}
                  />
                  {/* {selectedCustomer?.phone} */}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>E-mail</b>
                </TableCell>
                <TableCell>
                  <TextField
                    {...register("email", {
                      required: "Required*",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                    disabled
                    error={errors?.email && true}
                    helperText={errors?.email?.message}
                    size="small"
                    label="E-Mail"
                    variant="outlined"
                    value={customerDetails?.email ?? ""}
                    onChange={(e) => {
                      updateCustomerDetails("email", e?.target?.value);
                    }}
                    style={{ width: "100%", marginBottom: 15 }}
                  />
                  {/* {selectedCustomer?.email} */}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Pickup Address</b>
                </TableCell>
                <TableCell>
                  <Autocomplete
                    options={pickupOptions ?? []}
                    filterOptions={(x) => x}
                    value={customerDetails?.address ?? ""}
                    autoComplete
                    onKeyDown={async (e, value) => {
                      switch (e?.key) {
                        case "Enter":
                          const pAddressDetails = await addressSearch(e, value);
                          if (!pAddressDetails) {
                            return;
                          }
                          updateCustomerDetails("addressDetails", {
                            ...pAddressDetails,
                            country: pAddressDetails?.address_components?.find(
                              (e) => e?.types?.includes("country")
                            )?.long_name,
                            postCode: pAddressDetails?.address_components?.find(
                              (e) => e?.types?.includes("postal_code")
                            )?.long_name,
                            state: pAddressDetails?.address_components?.find(
                              (e) =>
                                e?.types?.includes(
                                  "administrative_area_level_1"
                                )
                            )?.long_name,
                            latitude: pAddressDetails?.geometry.location.lat(),
                            longitude: pAddressDetails?.geometry.location.lng(),
                            city: pAddressDetails?.address_components?.find(
                              (e) => e?.types?.includes("locality")
                            )?.long_name,
                          });
                          updateCustomerDetails(
                            "country",
                            pAddressDetails?.address_components?.find((e) =>
                              e?.types?.includes("country")
                            )?.long_name
                          );
                          updateCustomerDetails(
                            "postCode",
                            pAddressDetails?.address_components?.find((e) =>
                              e?.types?.includes("postal_code")
                            )?.long_name
                          );
                          updateCustomerDetails(
                            "latitude",
                            pAddressDetails?.geometry.location.lat()
                          );
                          updateCustomerDetails(
                            "longitude",
                            pAddressDetails?.geometry.location.lng()
                          );
                          updateCustomerDetails(
                            "city",
                            pAddressDetails?.address_components?.find((e) =>
                              e?.types?.includes("locality")
                            )?.long_name
                          );
                          updateCustomerDetails(
                            "address",
                            pAddressDetails?.formatted_address
                          );
                          break;
                        default:
                          break;
                      }
                    }}
                    onChange={async (e, value) => {
                      const pAddressDetails = await addressSearch(e, value);
                      if (!pAddressDetails) {
                        return;
                      }
                      updateCustomerDetails("addressDetails", {
                        ...pAddressDetails,
                        country: pAddressDetails?.address_components?.find(
                          (e) => e?.types?.includes("country")
                        )?.long_name,
                        postCode: pAddressDetails?.address_components?.find(
                          (e) => e?.types?.includes("postal_code")
                        )?.long_name,
                        state: pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("administrative_area_level_1")
                        )?.long_name,
                        latitude: pAddressDetails?.geometry.location.lat(),
                        longitude: pAddressDetails?.geometry.location.lng(),
                        city: pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("locality")
                        )?.long_name,
                      });
                      updateCustomerDetails(
                        "country",
                        pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("country")
                        )?.long_name
                      );
                      updateCustomerDetails(
                        "postCode",
                        pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("postal_code")
                        )?.long_name
                      );
                      updateCustomerDetails(
                        "latitude",
                        pAddressDetails?.geometry.location.lat()
                      );
                      updateCustomerDetails(
                        "longitude",
                        pAddressDetails?.geometry.location.lng()
                      );
                      updateCustomerDetails(
                        "city",
                        pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("locality")
                        )?.long_name
                      );

                      updateCustomerDetails(
                        "address",
                        pAddressDetails?.formatted_address
                      );
                    }}
                    // value={customerDetails?.address ?? ""}
                    // onChange={(e) => updateCustomerDetails("address", e?.target?.value)}
                    style={{ width: "100%", marginBottom: 15 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={errors?.address && true}
                        helperText={errors?.address?.message}
                        {...register("address", {
                          required: "Required*",
                        })}
                        label="Pickup Address"
                        variant="outlined"
                        onChange={(e) => {
                          GAutoComplete(e?.target?.value)
                            .then((res) => {
                              // console.log("Suggestions===>", res);
                              setPickupOptions(
                                res?.map((e) => ({
                                  label: e?.description,
                                  data: e,
                                }))
                              );
                            })
                            .catch(console.error);
                        }}
                      />
                    )}
                  />
                  {/* {selectedCustomer?.address} */}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Address</b>
                </TableCell>
                <TableCell>
                  <Autocomplete
                    options={customerAddressOptions ?? []}
                    filterOptions={(x) => x}
                    autoComplete
                    value={
                      customerDetails?.customerAddress ??
                      customerDetails?.address ??
                      ""
                    }
                    onKeyDown={async (e, value) => {
                      switch (e?.key) {
                        case "Enter":
                          const pAddressDetails = await addressSearch(e, value);
                          if (!pAddressDetails) {
                            return;
                          }
                          updateCustomerDetails("customerAddressDetails", {
                            ...pAddressDetails,
                            country: pAddressDetails?.address_components?.find(
                              (e) => e?.types?.includes("country")
                            )?.long_name,
                            postCode: pAddressDetails?.address_components?.find(
                              (e) => e?.types?.includes("postal_code")
                            )?.long_name,
                            state: pAddressDetails?.address_components?.find(
                              (e) =>
                                e?.types?.includes(
                                  "administrative_area_level_1"
                                )
                            )?.long_name,
                            latitude: pAddressDetails?.geometry.location.lat(),
                            longitude: pAddressDetails?.geometry.location.lng(),
                            city: pAddressDetails?.address_components?.find(
                              (e) => e?.types?.includes("locality")
                            )?.long_name,
                          });
                          updateCustomerDetails(
                            "customerAddress",
                            pAddressDetails?.formatted_address
                          );
                          break;
                        default:
                          break;
                      }
                    }}
                    onChange={async (e, value) => {
                      const pAddressDetails = await addressSearch(e, value);
                      if (!pAddressDetails) {
                        return;
                      }
                      updateCustomerDetails("customerAddressDetails", {
                        ...pAddressDetails,
                        country: pAddressDetails?.address_components?.find(
                          (e) => e?.types?.includes("country")
                        )?.long_name,
                        postCode: pAddressDetails?.address_components?.find(
                          (e) => e?.types?.includes("postal_code")
                        )?.long_name,
                        state: pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("administrative_area_level_1")
                        )?.long_name,
                        latitude: pAddressDetails?.geometry.location.lat(),
                        longitude: pAddressDetails?.geometry.location.lng(),
                        city: pAddressDetails?.address_components?.find((e) =>
                          e?.types?.includes("locality")
                        )?.long_name,
                      });
                      updateCustomerDetails(
                        "customerAddress",
                        pAddressDetails?.formatted_address
                      );
                    }}
                    // value={customerDetails?.address ?? ""}
                    // onChange={(e) => updateCustomerDetails("address", e?.target?.value)}
                    style={{ width: "100%", marginBottom: 15 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={errors?.customerAddress && true}
                        helperText={errors?.customerAddress?.message}
                        {...register("customerAddress", {
                          required: "Required*",
                        })}
                        label="Customer Address"
                        variant="outlined"
                        onChange={(e) => {
                          GAutoComplete(e?.target?.value)
                            .then((res) => {
                              // console.log("Suggestions===>", res);
                              setCustomerAddressOptions(
                                res?.map((e) => ({
                                  label: e?.description,
                                  data: e,
                                }))
                              );
                            })
                            .catch(console.error);
                        }}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <FormControlLabel
        label="Show Cost Centers"
        control={
          <Checkbox
            checked={selectedCustomer?.showCostCenters}
            defaultChecked={selectedCustomer?.selectedCostCenters?.length > 0}
            onClick={() => {
              setSelectedCustomer((pre) => {
                return { ...pre, showCostCenters: !pre?.showCostCenters };
              });
            }}
          />
        }
      />
      {(selectedCustomer?.showCostCenters ||
        selectedCustomer?.selectedCostCenters?.length > 0) && (
        <div>
          <br />
          <Typography variant="h5">Cost Centers</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Cost Center Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Cost Center</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>City</b>
                  </TableCell>
                  <TableCell>
                    <b>Postal</b>
                  </TableCell>
                  <TableCell>
                    <b>Province</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {costCenters?.length > 0 ? (
                  costCenters?.map((e, i) => (
                    <TableRow
                      hover
                      // selected={
                      //   selectedCostCenter?.costCenterName === e?.costCenterName
                      // }
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCostCenter(e);
                      }}
                    >
                      <TableCell>{e?.customerNumber}</TableCell>
                      <TableCell>
                        {e?.costCenterName ?? e?.customerName}
                      </TableCell>
                      <TableCell>{e?.address?.address ?? e?.address}</TableCell>
                      <TableCell>
                        {e?.city ?? e?.addressDetails?.city}
                      </TableCell>
                      <TableCell>
                        {e?.postalCode ?? e?.addressDetails?.postCode}
                      </TableCell>
                      <TableCell>
                        {e?.province ?? e?.addressDetails?.state}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          defaultChecked={customerDetails?.selectedCostCenters?.includes(
                            e?.customerID
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedCostCenters((pre) => [
                                ...pre,
                                e.customerID,
                              ]);
                            } else {
                              setSelectedCostCenters((pre) =>
                                pre?.filter((e) => e === e.customerID)
                              );
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setOpenAddCostCenter(true);
              }}
              sx={{ marginRight: 5 }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (selectedCostCenter === undefined) {
                  return;
                }
                setOpenDeleteCostCenter(true);
              }}
            >
              Delete
            </Button>
          </div> */}
        </div>
      )}
      <div style={{ display: "flex", gap: 10, flexDirection: "row-reverse" }}>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setConfirmUpdateCustomer(true);
          }}
          autoFocus
          variant="contained"
        >
          Update
        </Button>
      </div>
      <AlertDialog
        customControls={false}
        open={openAddCostCenter}
        handleClose={() => {
          setOpenAddCostCenter(false);
        }}
        dialogTitle={`Add Cost Center`}
        dialogContent={
          <AddCostCenter
            handleClose={() => {
              setOpenAddCostCenter(false);
            }}
            openAlertMessage={openAlertMessage}
            setOpenAlertMessage={setOpenAlertMessage}
            errorMessage={errorMessage}
            onAddCostCenter={async (e) => {
              const addCCRes = await addCostCenter(
                selectedCustomer?.customerID,
                e,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              // setCostCenters((pre) => [...(pre ?? []), e]);
              if (String(addCCRes?.response?.statusCode) === "400") {
                setErrorMessage(addCCRes?.response);
                setOpenAlertMessage(true);
              } else {
                await refreshCustomer();
                setOpenAddCostCenter(false);
              }
            }}
          />
        }
      />
      <AlertDialog
        open={confirmUpdateCustomer}
        handleClose={() => {
          setConfirmUpdateCustomer(false);
        }}
        handleConfirm={async () => {
          await updateCustomerAPI(
            {
              ...customerDetails,
              selectedCostCenters,
              customerAddress:
                customerDetails?.customerAddress ?? customerDetails?.address,
              customerAddressDetails:
                customerDetails?.customerAddressDetails ??
                customerDetails?.addressDetails,
            },
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            setConfirmUpdateCustomer(false);
            // if (!res?.response?.success) {
            //   setSnackbarMessage({
            //     message: res?.response?.error,
            //     type: "error",
            //   });
            //   setOpenSnackbar(true);
            //   return;
            // }
            // setSnackbarMessage({
            //   message: "Customer Added",
            //   type: "success",
            // });
            // setOpenSnackbar(true);
            // refresh();
            handleConfirm();
          });
          //   resetForm();
        }}
        dialogTitle="Confirm Add Customer"
      />
      <AlertDialog
        open={openDeleteCostCenter}
        handleClose={() => {
          setOpenDeleteCostCenter(false);
        }}
        handleConfirm={async () => {
          await deleteCostCenter(
            selectedCustomer?.customerID,
            selectedCostCenter?.costCenterID,
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          );
          await refreshCustomer();
          setOpenDeleteCostCenter(false);
        }}
        dialogTitle={`Confirm Delete Cost Center: ${selectedCostCenter?.costCenterName}`}
      />
    </div>
  );
}
