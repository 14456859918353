import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useContext, useEffect, useState } from "react";
import AddZoneSet from "./AddZoneSet";
import { getZoneSets, updateZoneSetZone } from "./functions";
import ZoneSetDetails from "./ZoneSetDetails";
import { VishcorpContext } from "Context";
import { deleteZoneSet } from "API/Pricing";
import { AttachFile } from "@mui/icons-material";
import papaparse from "papaparse";

export default function ZoneSets(props) {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDownloadTemplate = () => {
    const fileUrl =
      "https://vishcorp-organizations.s3.ap-southeast-2.amazonaws.com/ZoneSet%20Upload%20Doc%20-%20Sheet1.csv?response-content-disposition=attachment&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEMb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0yIkYwRAIgeMFIvHgwy%2FyVnujwgykv7ttri5ffbpYa2PooOxeMd%2BoCIErBPCEHSJHrhSVvOSbnNTLSkS9vsNhtYPns4WVuW0zmKvECCO%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNTYzMDU0NTk0NTE0IgwqPAMJfFnXJ1uOFVoqxQKnMlzsdLPAlSfDCoq%2Bkx0MvgmiWvF3FDhSEBMcjuoPnI6k0WIXdTO7HZMM2L%2FXMXHlcwibgwVynY77VIIyZQ1B6I6B3SGR4x4SaKhS6ItXLBn049EEqwEw1xGc1UM4taEDpNK2dXFmElV16Un5%2BkfGfstrkQWBOP37ESfSvZVgbTIBkUoJCsX4n4ME6NL5k2nk6Kj0u6HjD%2ByitFDRPcxW0snybk4mHRVOsnG2%2FroKPm6ZzG42eQH8DPVcwot3LcFhbMFQdSMy9LhQrp8BHcVCd%2FJ%2BhVn6ZpuPDhYJ%2F9r%2B4hvqTwztcEljJDrN%2BnfWhCU99Mb%2BwtjIW0Zcy3UmCCuGdiz38XluKimWIzzyk0g5UkcG%2BEbjF9bR1sxGbwt8PUx%2FJopj5sZciRTLV0PicVmKqmU74nES1r%2BbnI7UZnILxgbizY%2FcMMO9m7cGOrQCNnPy4ZlfXSZQlx06oe214Pi9EHiw01GpsbiHejuPoRORL6kbw0jQb1bblT5BVdzuSFcZq7FVN1WHMjJyvF1jWde2sBwYpriLzf4tB5PkC3jRZWMK8wIT1GeyxLdY6lgNSoaT1lGgG53g2klx8QBXTIdN2MbGjnaqV19d2YJ8vRjYrZIZjJgdHYfJh7qUs7ELfq1KF93FUe9kLrYUiUA10ZnqZcOXbLTlzwKxtPFuKz2GqJJ328S2o6w0qGw0ELcYJZPqlbK70nwV5CKKNdVqCv8STl3e95M%2F7YW%2F59ZmNnUDqQ%2FPSSZ8cvYHrNXhAdidZcUA8Fo6Ci66zgHBm5bW%2F79Lo53rE%2FC%2BYMu8YMPkZo92NmLpBm2sXFefF7w2J4DINHrCONtUVQF6vu1FpnX%2B%2FA5%2FlYc%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240915T135341Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAYGGFLJHJCVL22RUQ%2F20240915%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Signature=992d5748eda9822380ade8727c5295a6b3ae15b47af255724668a433ad9ca1af"; // Replace with your template file URL
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "template.csv"); // File name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { orgSettings } = useContext(VishcorpContext);
  const [zoneSets, setZoneSets] = useState(undefined);
  const [selectedZoneSet, setSelectedZoneSet] = useState(undefined);

  const [openZoneSetDialog, setOpenZoneSetDialog] = useState(false);
  const [confirmDeleteZoneSet, setConfirmDeleteZoneSet] = useState(false);

  const refreshZoneSets = async () => {
    await getZoneSets(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setZoneSets(res);
        if (selectedZoneSet) {
          setSelectedZoneSet(
            res?.find((e) => e?.zoneSetName === selectedZoneSet?.zoneSetName)
          );
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    refreshZoneSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography>Select a zone set to edit</Typography>
        {/* <Box sx={{ display: "flex", border: 1, height: "100%" }}> */}
        <Paper sx={{ display: "flex", height: "70vh" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b>Zone Sets</b>
                      <Tooltip title="Upload Zone Set CSV">
                        <IconButton color="primary" onClick={handleClickOpen}>
                          <FileUploadIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zoneSets &&
                  zoneSets?.map((e, i) => (
                    <TableRow
                      hover
                      key={i}
                      selected={selectedZoneSet?.zoneSetName === e?.zoneSetName}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedZoneSet(e);
                      }}
                    >
                      <TableCell>{e?.zoneSetName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={(e) => {
              setOpenZoneSetDialog(true);
            }}
          >
            Add Zone Set
          </Button>
          <Button
            variant="contained"
            onClick={() => setConfirmDeleteZoneSet(true)}
          >
            Remove Zone Set
          </Button>
        </div>
        {/* </Box> */}
      </Grid>
      {/* <Grid item xs={1}> */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ marginLeft: 2, height: "100vh" }}
      />
      {/* </Grid> */}
      <Grid item xs={7.5}>
        {selectedZoneSet && (
          <ZoneSetDetails
            zoneSetDetails={selectedZoneSet}
            updateZoneSet={async (e) => {
              await updateZoneSetZone(
                e,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              refreshZoneSets();
            }}
            refreshZoneSets={refreshZoneSets}
            selectedZoneSet={selectedZoneSet?.zoneSetName}
          />
        )}
      </Grid>
      <AlertDialog
        customControls={false}
        open={openZoneSetDialog}
        handleClose={() => setOpenZoneSetDialog(false)}
        dialogContent={
          <AddZoneSet
            zoneSetNames={zoneSets?.map((e) => e?.zoneSetName)}
            onCancel={() => setOpenZoneSetDialog(false)}
            onRefresh={async () => {
              await refreshZoneSets();
            }}
          />
        }
      />
      <AlertDialog
        open={confirmDeleteZoneSet}
        handleClose={() => setConfirmDeleteZoneSet(false)}
        handleConfirm={async () => {
          if (!selectedZoneSet?.zoneSetName) {
            return;
          }
          await deleteZoneSet(
            selectedZoneSet?.zoneSetName,
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          );
          await refreshZoneSets();
          setConfirmDeleteZoneSet(false);
        }}
        dialogTitle={`Delete Zone Set: ${selectedZoneSet?.zoneSetName}?`}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="error" gutterBottom>
            Note: It is very important that the file name matches the zone set
            name you want to upload.
          </Typography>
          {/* Download Template Button */}
          <Button
            variant="outlined"
            size="small"
            endIcon={<AttachFile />}
            onClick={handleDownloadTemplate}
          >
            Download Template File
          </Button>
          <br />
          {/* File Upload Input */}
          <TextField
            margin="dense"
            id="file"
            label="Upload File"
            type="file"
            fullWidth
            onChange={handleFileChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: ".csv", // Restrict file types if needed
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (selectedFile) {
                console.log(selectedFile);
                papaparse.parse(selectedFile, {
                  complete: (result) => {
                    console.log({
                      organizationID: orgSettings?.organizationID,
                      zoneSetName: selectedFile.name,
                      zones: result.data.reduce((acc, e) => {
                        var currentObj =
                          acc.find((j) => j?.zoneName === e["Zone Name"]) ?? {};
                        var currentObjIndex =
                          acc.findIndex(
                            (j) => j?.zoneName === e["Zone Name"]
                          ) ?? {};
                        var newObj = {
                          zoneName: e["Zone Name"],
                          includedOptions: {
                            cities: [
                              ...(currentObj?.includedOptions?.cities ?? []),
                              e?.["City Name"] !== undefined &&
                              e?.["City Name"] !== "" &&
                              e?.["City Name"] !== null
                                ? {
                                    cityDetails: {
                                      address: "",
                                      city: e?.["City Name"],
                                      country: e?.["Country"],
                                      state: e?.["State"],
                                      postCode: e?.["Post Code"],
                                      latitude: 0,
                                      longitude: 0,
                                    },
                                    cityName: e?.["City Name"],
                                  }
                                : undefined,
                            ].filter((e) => e !== undefined),
                            companies: [
                              ...(currentObj?.includedOptions?.companies ?? []),
                              e?.["Company Name"] !== undefined &&
                              e?.["Company Name"] !== "" &&
                              e?.["Company Name"] !== null
                                ? {
                                    companyName: e?.["Company Name"],
                                  }
                                : undefined,
                            ].filter((e) => e !== undefined),
                            drivers: [],
                            mapZones: [],
                            postalCodes: [
                              ...(currentObj?.includedOptions?.postalCodes ??
                                []),
                              e?.["Post Code"] !== undefined &&
                              e?.["Post Code"] !== "" &&
                              e?.["Post Code"] !== null
                                ? {
                                    postalCode: e?.["Post Code"],
                                  }
                                : undefined,
                            ].filter((e) => e !== undefined),
                          },
                        };
                        if (currentObjIndex > -1) {
                          acc[currentObjIndex] = newObj;
                        } else {
                          acc.push(newObj);
                        }
                        return acc;
                      }, []),
                    });
                  },
                  header: true,
                  dynamicTyping: true,
                  skipEmptyLines: true,
                });
              }
            }}
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
