import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Papa from "papaparse";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { uploadPricingList } from "../functions";
import { addressSearchByCity } from "Components/GAutoComplete";

export default function UploadPricingList({
  zoneSetList,
  onCancel,
  organizationID,
  organizationTablePrefix,
}) {
  const [parsedData, setParsedData] = useState([]);
  const [confirmUploadPriceList, setConfirmUploadPriceList] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedZoneSet, setSelectedZoneSet] = useState(undefined);
  const [validationError, setValidationError] = useState("");

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [pricingListName, setPricingListName] = useState(undefined);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected and if it is a CSV file
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFileFormatError(
            results?.data?.some((e) => !Object.keys(e).includes("Zone"))
          );
          if (results?.data?.some((e) => !Object.keys(e).includes("Zone"))) {
            setParsedData(undefined);
            return;
          }
          // Parsed Data Response in array format
          const data = results.data.map((obj) => {
            obj.zoneName = obj.Zone;
            delete obj.Zone;
            return obj;
          });
          setParsedData(data);
        },
      });
    } else {
      setErrorMessage({
        open: true,
        message: "Please select a valid CSV file.",
        severity: "error",
      });
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      return;
    }
    setIsUploading(true);
    const results = await Promise.all(
      parsedData?.map(async (e) => {
        let cityDetails;
        try {
          cityDetails = await addressSearchByCity(e.zoneName);
        } catch (ex) {
          console.error(ex);
        }
        return {
          zoneName: e.zoneName,
          includedOptions: {
            cities: [{ cityName: e.zoneName, cityDetails }],
          },
        };
      })
    );

    const existingZones =
      zoneSetList?.find((e) => selectedZoneSet === e.zoneSetName)?.zones ?? [];

    // Replace zones with the corresponding ones from results
    const updatedZones = existingZones.map((existingZone) => {
      const matchingResult = results?.find(
        (resultZone) => resultZone?.zoneName === existingZone?.zoneName
      );
      return matchingResult ? matchingResult : existingZone;
    });

    // Add new zones from results that don't exist in existingZones
    const newZones = (results ?? []).filter(
      (resultZone) =>
        !existingZones.some(
          (existingZone) => existingZone.zoneName === resultZone.zoneName
        )
    );

    const finalZones = [...updatedZones, ...newZones];

    await uploadPricingList(
      {
        selectedZoneSet,
        zonePricingList: parsedData,
        zones: finalZones,
      },
      pricingListName,
      "zoneBased",
      organizationID,
      organizationTablePrefix
    ).then((res) => {
      setErrorMessage({
        open: true,
        message: res?.success
          ? "Price List Upload Successful."
          : "Price List Upload Failed.",
        severity: res?.success ? "success" : "error",
      });
    });
    setIsUploading(false);
  };

  return (
    <>
      <Typography variant="h4">Upload Pricing List</Typography>
      <br />
      <input type="file" onChange={handleFileChange} accept=".csv" />
      <br />
      <br />
      <TextField
        fullWidth
        size="small"
        label="Price List Name"
        value={pricingListName}
        onChange={(e) => setPricingListName(e.target.value)}
      />
      <br />
      <br />
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="price-list-label">Zone Set</InputLabel>
        <Select
          fullWidth
          value={selectedZoneSet}
          size="small"
          label="Zone Set"
          onChange={(e) => {
            setSelectedZoneSet(e?.target?.value);
          }}
          sx={{ marginBottom: 1 }}
        >
          {zoneSetList?.length > 0 &&
            zoneSetList?.map((e, i) => (
              <MenuItem value={e?.zoneSetName} key={i}>
                {e?.zoneSetName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {fileFormatError && (
        <FormHelperText error>Error in Upload File Format.</FormHelperText>
      )}
      {validationError !== "" && (
        <FormHelperText error>{validationError}</FormHelperText>
      )}
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Button
          disabled={
            selectedFile === undefined ||
            parsedData === undefined ||
            pricingListName === undefined ||
            pricingListName === "" ||
            organizationID === undefined ||
            organizationTablePrefix === undefined ||
            isUploading
          }
          variant="contained"
          onClick={() => {
            if (!pricingListName || !selectedZoneSet) {
              setValidationError("Please fill all the required fields.");
              return;
            }
            setConfirmUploadPriceList(true);
          }}
        >
          {isUploading ? "Uploading" : "Upload"}
        </Button>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <Snackbar
        open={errorMessage?.open}
        autoHideDuration={3000}
        onClose={() => {
          setErrorMessage({ open: false });
          if (errorMessage?.severity === "success") {
            onCancel();
          }
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessage({ open: false });
            if (errorMessage?.severity === "success") {
              onCancel();
            }
          }}
          severity={errorMessage?.severity}
          sx={{ width: "100%" }}
        >
          {errorMessage?.message}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={confirmUploadPriceList}
        handleClose={() => setConfirmUploadPriceList(false)}
        dialogTitle={`Confirm Upload Pricing List: ${pricingListName}?`}
        handleConfirm={async () => {
          try {
            handleFileUpload();
          } catch (error) {
            console.error(error);
          }
          setConfirmUploadPriceList(false);
        }}
      />
    </>
  );
}
