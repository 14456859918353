export const GAutoComplete = async (text) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input");
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === "undefined") {
      return reject("Need valid window object");
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: text,
          componentRestrictions: { country: "au" },
          fields: ["address_components", "formatted_address"],
        },
        resolve
      );
      // .setFields(["address_components", "formatted_address"]);
    } catch (e) {
      reject(e);
    }
  });

export const addressSearchByCity = async (cityName) => {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: cityName }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const firstResult = results[0];
          const addressComponents = firstResult.address_components;
          const postCode = addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const country = addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name;
          const state = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name;
          const city = addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          )?.long_name;
          resolve({
            address: firstResult.formatted_address,
            placeId: firstResult.place_id,
            // ...firstResult,
            latitude: firstResult.geometry.location.lat(),
            longitude: firstResult.geometry.location.lng(),
            country,
            state,
            city,
            postCode,
          });
        } else {
          reject(status);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const addressSearch = async (event, value) => {
  return new Promise((resolve, reject) => {
    try {
      // const pSelectedOption = deliveryOptions.find(
      //   (e) => e?.label === event?.target?.value
      // );
      if (value?.data?.place_id) {
        new window.google.maps.places.PlacesService(event?.target).getDetails(
          { placeId: value?.data?.place_id },
          (place, status) => {
            if (status === "OK") {
              resolve(place);
            }
          }
        );
      }
    } catch (error) {
      reject(error);
    }
  });
};
