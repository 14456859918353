import React, { useContext, useEffect, useState } from "react";
import Tab from "Components/Tabs/Tab";
import CustomerList from "./CustomerList";
import { primaryButtonColor } from "constants";
import { Button, FormLabel } from "@mui/material";
import { primaryButtonHoverColor } from "constants";
import AddCustomer from "./AddCustomer";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { deleteCustomer, getCustomers } from "API/Customers";
import { VishcorpContext } from "Context";

export default function Customers(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const [customers, setCustomers] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);

  const refresh = () =>
    getCustomers(orgSettings?.organizationTablePrefix).then((res) => {
      setCustomers(res?.customers?.Items);
    });

  useEffect(() => {
    getCustomers(orgSettings?.organizationTablePrefix).then((res) =>
      setCustomers(res?.customers?.Items)
    );
  }, [orgSettings]);

  return (
    <Tab title="Customers">
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignContent: "right",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {
            // setDialogOpen(true);
          }}
        >
          Get Customer Online
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          Add Customer
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {
            if (customers?.[selected] !== undefined) {
              setShowAlert(true);
            }
            // setDialogOpen(true);
          }}
        >
          Delete Customer
        </Button>
      </div>
      <br />
      <CustomerList
        customers={customers}
        selected={selected}
        setSelected={setSelected}
        refresh={refresh}
        deleteCustomer
      />
      {/* <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <AddCustomer
          handleClose={() => {
            getCustomers(orgSettings?.organizationTablePrefix);
            setDialogOpen(false);
          }}
        />
      </Dialog> */}
      <AlertDialog
        customControls={false}
        open={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);
        }}
        dialogContent={
          <AddCustomer
            handleClose={() => {
              getCustomers(orgSettings?.organizationTablePrefix);
              setDialogOpen(false);
            }}
            refresh={() => {
              refresh();
            }}
          />
        }
      />
      <AlertDialog
        open={showAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        handleConfirm={async () => {
          await deleteCustomer(
            customers?.[selected]?.customerID,
            orgSettings?.organizationTablePrefix
          );
          getCustomers(orgSettings?.organizationTablePrefix).then((res) => {
            setCustomers(res?.customers?.Items);
          });
          setShowAlert(false);
          getCustomers(orgSettings?.organizationTablePrefix);
        }}
        dialogTitle=""
        dialogContent={
          <FormLabel>
            <b>
              Confirm Delete Customer: {customers?.[selected]?.customerNumber} -{" "}
              {customers?.[selected]?.customerName}?
            </b>
          </FormLabel>
        }
      />
    </Tab>
  );
}
